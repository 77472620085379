import Logo from '../../images/logos/Qulto_logo_white.png';
import './style.scss';

const Footer = () => (
  <div className="footer">
    <img src={Logo} alt="logo" />
  </div>
);

export default Footer;
