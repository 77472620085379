import { FaArrowLeft } from 'react-icons/fa';
import Logo from '../../images/logos/ngytm_logo_szoveg.png';
import './style.scss';

const ContentHeader = ({ title }) => (
  <div className="content-header">
    <FaArrowLeft className="icon" onClick={() => window.location.reload()} />
    <div className="title"> {title}</div>
    <img src={Logo} alt="logo" />
  </div>
);

export default ContentHeader;
