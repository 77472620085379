import Tile from '../Tile';
import './style.scss';

const Section = ({ data }) => {
  const renderTiles = () => {
    return data.tiles.map((tile, index) => <Tile key={index} icon={data.icon} tile={tile} />);
  };
  return data?.title ? (
    <div className="section">
      <div className="section-title">{data.title}</div>
      <div className="tile-container">{renderTiles()}</div>
    </div>
  ) : (
    renderTiles()
  );
};
export default Section;
