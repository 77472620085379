import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Category from '../Category';
import './style.scss';
import Header from '../Header';
import Footer from '../Footer';

const Menu = () => {
  const { type } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const response = await fetch('data.json');
      const fetchedData = await response.json();
      setData(fetchedData);
    })();
  }, []);

  return (
    <>
      <Header />

      {data && (
        <div className="menu">
          {data[type]?.map((category, index) => (
            <Category key={index} data={category} />
          ))}
        </div>
      )}

      <Footer />
    </>
  );
};

export default Menu;
