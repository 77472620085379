import { useLocation } from 'react-router-dom';
import './style.scss';
import ContentHeader from '../ContentHeader';
import ContentFooter from '../ContentFooter';

const ContentPage = () => {
  const {
    state: { url, title },
  } = useLocation();

  return (
    <>
      <ContentHeader title={title} />
      <div className="content-container">
        <iframe title={title} src={url} className="content" scrolling="no"></iframe>
      </div>
      <ContentFooter />
    </>
  );
};

export default ContentPage;
