import { FaHome } from 'react-icons/fa';
import Logo from '../../images/logos/Qulto_logo_white.png';
import './style.scss';
import { useNavigate } from 'react-router-dom';

const ContentFooter = () => {
  const navigate = useNavigate();
  return (
    <div className="content-footer">
      <FaHome className="icon" onClick={() => navigate('..')} />
      <img src={Logo} alt="logo" />
    </div>
  );
};

export default ContentFooter;
