import Logo from '../../images/logos/ngytm_logo_szoveg.png';
import './style.scss';

const Header = () => (
  <div className="header">
    <img src={Logo} alt="logo" />
  </div>
);

export default Header;
