import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './style.scss';

import Menu from '../Menu';
import ContentPage from '../ContentPage';

const App = () => (
  <div className="app">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/86" />} />
        <Route path="/:type">
          <Route index element={<Menu />} />
          <Route path="content" element={<ContentPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
