import { useLayoutEffect, useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import './style.scss';
import classNames from 'classnames';
import Section from '../Section';

const Category = ({ data }) => {
  const [height, setHeight] = useState(8000);
  const [open, setOpen] = useState(true);
  const ref = useRef();

  useLayoutEffect(() => {
    setHeight(ref.current.offsetHeight + 100);
  }, []);

  return (
    <div className={classNames('category', open && 'open')}>
      <div className="category-title" onClick={() => setOpen(prev => !prev)}>
        {data.title}
        <FaChevronDown className="dropdown-icon" />
      </div>
      <div ref={ref} className="section-container" style={{ maxHeight: open ? height : 0 }}>
        {data.sections.map((section, index) => (
          <Section key={index} data={section} />
        ))}
      </div>
    </div>
  );
};

export default Category;
