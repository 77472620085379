import classNames from 'classnames';
import './style.scss';
import { useNavigate } from 'react-router-dom';

const Tile = ({ icon, tile }) => {
  const backgroundImage = tile?.background
    ? require(`../../images/tile-background-icons/${tile?.background}`)
    : undefined;
  const navigate = useNavigate();

  const onClick = () => {
    navigate('./content', { state: { url: tile.url, title: tile.title } });
  };

  return (
    <div className={classNames('tile', backgroundImage && 'has-background-icon')} onClick={onClick}>
      {backgroundImage ? (
        <div
          className={classNames('background-icon', !icon && 'no-icon')}
          style={{
            backgroundImage: `url("${backgroundImage}")`,
          }}
        ></div>
      ) : (
        <div className="color" style={{ backgroundColor: tile.color }}></div>
      )}
      <div className="title">{tile.title}</div>
      {icon && <img className="icon" src={require(`../../images/tile-icons/${icon}`)} alt="icon" />}
    </div>
  );
};

export default Tile;
